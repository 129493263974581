import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';

import { Link } from "gatsby";

import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    padding: 16,
    justifyContent: 'end'
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default ({data}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {data.map((tag) => {
        return (
          <Link to={`/tags/${_.kebabCase(tag)}`} key={data.index}>
            <Chip
              label={tag}
              className={`${classes.chip} MuiPaper-elevation1`}
            />
          </Link>
        );
      })}
    </div>
  );
}
