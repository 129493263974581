import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CartIcon from "./../Icons/Cart";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import { gql, useMutation } from "@apollo/client";

const MAKE_ORDER = gql`
  mutation MakeOrder(
    $name: String!
    $company: String!
    $email: String
    $phone: String
    $country: String!
    $governorate: String!
    $zipcode: String!
    $product: String!
    $quantity: String!
    $note: String
  ) {
    makeOrder(
      name: $name
      company: $company
      email: $email
      phone: $phone
      country: $country
      governorate: $governorate
      zipcode: $zipcode
      product: $product
      quantity: $quantity
      note: $note
    )
  }
`;

const initialState = {
  name: "",
  company: "",
  email: "",
  phone: "",
  country: "",
  governorate: "",
  zipcode: "",
  product: "",
  quantity: "",
  note: "",
  isSubmitting: false,
  errorMessage: undefined,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: '25ch',
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
}));

export default ({ product }) => {
  const classes = useStyles();

  const [orderData, setOrderData] = React.useState(initialState);

  const [makeOrder, { data, loading, error }] = useMutation(MAKE_ORDER, {
    onCompleted: (data) => {
      setOpen(false);
    },
    onError: (error) => {
      console.log(error);
      setOrderData({
        ...loginData,
        isSubmitting: false,
        errorMessage: error,
      });
    },
  });

  const handleInputChange = (event) => {
    setOrderData({
      ...orderData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setOrderData({
      ...orderData,
      isSubmitting: true,
      errorMessage: undefined,
    });

    makeOrder({
      variables: {
        name: orderData.name,
        company: orderData.company,
        email: orderData.email,
        phone: orderData.phone,
        country: orderData.country,
        governorate: orderData.governorate,
        zipcode: orderData.zipcode,
        product: product,
        quantity: orderData.quantity,
        note: orderData.note,
      },
    });
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        style={{
          backgroundColor: "#FF9800",
          padding: "7px 20px",
        }}
        onClick={handleClickOpen}
        startIcon={
          <CartIcon color="white" size="30px" style={{ margin: "0 10px" }} />
        }
      >
        انشاء طلبية
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleFormSubmit}>
          <DialogTitle id="form-dialog-title">Order Form</DialogTitle>
          {/*loginData.errorMessage && (
          <span className="form-error">{loginData.errorMessage}</span>
        )*/}

          <DialogContent>
            <div className={classes.root}>
              <TextField
                id="fied-ful-widh"
                label="الاسم رباعى"
                style={{ margin: 8 }}
                placeholder="Placeholder"
                helperText="Require feild *"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                value={orderData.name}
                onChange={handleInputChange}
                name="name"
                className={classes.textField}

              />
              <TextField
                id="filled-fll-width"
                label="اسم الشركة"
                style={{ margin: 8 }}
                placeholder="Placeholder"
                helperText="Require feild *"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                value={orderData.company}
                onChange={handleInputChange}
                name="company"
                className={classes.textField}

              />

              <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                <TextField
                  label="ايميل الشركة"
                  id="filledrgin-dense"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Require feild *"
                  margin="normal"
                  variant="filled"
                  style={{ width: "49%" }}
                  value={orderData.email}
                  onChange={handleInputChange}
                  name="email"
                />
                <TextField
                  label="تليفون الشركة"
                  id="fillergin-ormal"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Require feild *"
                  margin="normal"
                  variant="filled"
                  style={{ width: "45%" }}
                  value={orderData.phone}
                  onChange={handleInputChange}
                  name="phone"
                />
              </div>
              <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                <TextField
                  label="الدوله"
                  id="filledgin-none"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Require feild *"
                  margin="normal"
                  variant="filled"
                  style={{ width: "33%" }}
                  value={orderData.country}
                  onChange={handleInputChange}
                  name="country"
                />
                <TextField
                  label="المحافظة"
                  id="filled-margin-dense"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Require feild *"
                  margin="normal"
                  variant="filled"
                  style={{ width: "33%" }}
                  value={orderData.governorate}
                  onChange={handleInputChange}
                  name="governorate"
                />
                <TextField
                  label="الرمز البريدى"
                  id="filled-lin-normal"
                  defaultValue="Default Value"
                  className={classes.textField}
                  helperText="Require feild *"
                  margin="normal"
                  variant="filled"
                  style={{ width: "25%" }}
                  value={orderData.zipcode}
                  onChange={handleInputChange}
                  name="zipcode"
                />
              </div>
            </div>

            <Divider />

            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <TextField
                id="filled-read-only-input"
                label="اسم المنتج"
                defaultValue={product}
                InputProps={{
                  readOnly: true,
                }}
                className={classes.textField}
                variant="filled"
                value={product}
                style={{ width: "60%" }}
                name="product"
              />
              <TextField
                id="filled-number"
                label="الكميه"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                variant="filled"
                helperText="Some important text"
                style={{ width: "34%" }}
                defaultValue={0}
                value={orderData.quantity}
                onChange={handleInputChange}
                name="quantity"
              />
            </div>

            <TextField
              id="outlined-multiline-static"
              label="الملاحظات"
              multiline
              rows={4}
              defaultValue="Default Value"
              variant="filled"
              fullWidth
              className={classes.textField}
              value={orderData.note}
              onChange={handleInputChange}
              name="note"
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={orderData.isSubmitting}
              disableElevation
            >
              {orderData.isSubmitting ? "جارى ارسال الطلب ..." : "ارسال الطلب"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
