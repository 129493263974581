import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import config from "../../data/SiteConfig";
import Img from "gatsby-image";

import Layout from "../layout";

//import SEO from "../components/SEO/SEO";
import Footer from "../components/Footer";
//import SideNav from '../components/SideNav';
import ProductCard from "../components/Product/ProductCard";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import FacebookIcon from "../components/Icons/Facebook";

import CartIcon from "../components/Icons/Cart";
import ListIcon from "../components/Icons/List";

import Chip from "../components/Chip";

import MakeOrder from "../components/MakeOrder";

import _ from "lodash";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },

  link: {
    display: "flex",
    textDecoration: "none",
    color: "#252525",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },

  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

/*
const productData = [
  {
   url: 'بسله_مجمده',
   image: 'https://res.cloudinary.com/dueytrbcj/image/upload/v1606049134/edsh_1552752807.png',
   tittle: 'بسله مجمده ',
   category: ' الخضروات'
  },
  {
   url: 'بسله_مجمده',
   image: 'https://res.cloudinary.com/dueytrbcj/image/upload/v1606049127/broad-bean_1552768058.jpg',
   tittle: 'بسله مجمده ',
   category: ' الخضروات'
  },
  {
   url: 'بسله_مجمده',
   image: 'https://res.cloudinary.com/dueytrbcj/image/upload/v1606049128/artichokes_1552769075.jpg',
   tittle: 'بسله مجمده ',
   category: ' الخضروات'
  },
];

*/

export default function PostTemplate({ data, pageContext }) {
  const classes = useStyles();

  const { slug } = pageContext;

  const postNode = data.markdownRemark;

  const post = postNode.frontmatter;
  if (!post.id) {
    post.id = slug;
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${post.title} | ${config.siteTitle}`}</title>
      </Helmet>

      {/*  <SEO postPath={slug} postNode={postNode} postSEO />  */}

      <Breadcrumbs aria-label="breadcrumb" style={{ padding: "59px 30px 0px" }}>
        <Link to="/" className={classes.link}>
          <span style={{ margin: "0 10px" }}>
            <ListIcon color="black" size="25px" style={{ margin: "0 10px" }} />
          </span>
          منتجات مماس
        </Link>

        <Link
          to={`/categories/${_.kebabCase(post.category)}`}
          className={classes.link}
        >
          {post.category}
        </Link>

        <Typography
          color="textPrimary"
          className={classes.link}
          style={{
            color: "#75c045",
          }}
        >
          {post.title}
        </Typography>
      </Breadcrumbs>

      <Container
        style={{
          margin: "30px 0",
        }}
      >
        <Card style={{ padding: 38, width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} sm={12}>
              <Img
                fluid={post.cover.childImageSharp.fluid}
                alt={post.title}
                style={{ width: "100%" ,
                         maxHeight: 425
                       }}
              />
            </Grid>

            <Grid item md={6} xs={12} sm={12}>
              <Typography component="h4" variant="h4">
                {post.title}
              </Typography>

              <Typography
                variant="body1"
                style={{
                  margin: "25px 0",
                  minHeight: 200,
                }}
                dangerouslySetInnerHTML={{ __html: postNode.html }}
                gutterBottom
              />

              <MakeOrder product={post.title} />
            </Grid>
          </Grid>

          <div />
        </Card>

        <Chip data={post.tags} />
      </Container>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
